import {
  GymPageProps,
  BlogPostPageProps,
  WagtailPageProps,
  SiteSettingProps
} from './types'
import logo_blue from '../../static/imgs/logo.jpg'
import { SiteProps } from '../context/SiteContext'
import { ReviewAPIProps } from '../../components/customer-reviews'
import { FooterProps } from '../../components'
const defaultTitle = 'Plus Fitness'
const defaultDescription =
  'Plus Fitness 24/7 Gym - Your Local Gym with over 200 convenient locations across Australia. No Lock In Contracts, Low Prices!'

export const StructureData = {
  Organization: (siteInfor?: SiteProps, flatMenuData?: FooterProps) => {
    let obj = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: siteInfor?.site_name,
      url: siteInfor?.root_page,
      logo: logo_blue,
      sameAs: flatMenuData?.social.socialLinks.map(s => s.url)
    }
    return obj
  },
  ExerciseGym: (data: GymPageProps) => {
    const gymBlock = data.flexible_content?.find(s => s.type == 'gym_location')
    const reviews = data.flexible_content?.find(
      s => s.type == 'FeaturedReviews'
    )
    let obj = {
      '@context': 'http://schema.org',
      '@type': 'ExerciseGym',
      name: `Plus Fitness ${data.title}`,
      url: data?.meta?.html_url,
      telephone: data.phone_number,
      image: logo_blue,
      logo: logo_blue,

      email: data.email,
      address: {
        '@type': 'PostalAddress',
        addressLocality: data.address_suburb,
        addressRegion: data.address_state_code,
        postalCode: data.address_postcode,
        streetAddress: data.address_street
      },
      openingHours:
        gymBlock &&
        gymBlock.value.staffed_hours &&
        gymBlock.value.staffed_hours.map((s: any) => `${s.day} ${s.times}`),

      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: data.phone_number,
          contactType: 'sales'
        }
      ],
      review: []
    }
    if (reviews) {
      obj.review = reviews.value.reviews.map((review: ReviewAPIProps) => {
        return {
          '@type': 'Review',
          author: review.author,
          datePublished: review.date,
          name: review.content.split('.')[0],
          reviewBody: review.content,
          reviewRating: {
            '@type': 'Rating',
            ratingValue: review.rating
          }
        }
      })
    }
    return obj
  },
  NewsArticle: (data: BlogPostPageProps) => {
    let images: string[] = []
    if (data.header_image) {
      images.push(data.header_image.url)
    }
    data.flexible_content
      ?.filter(s => ['image_gallery', 'featured_image'].includes(s.type))
      .map(s => {
        switch (s.value) {
          case 'image_gallery': {
            images = images.concat(s.value.map((img: any) => img.image))
            break
          }
          case 'featured_image': {
            images.push(s.value)
          }
        }
      })
    let obj = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': data?.meta?.html_url
      },
      headline: data.title,
      image: images,
      datePublished: data.meta.first_published_at,
      dateModified: data.meta.first_published_at,
      publisher: {
        '@type': 'Organization',
        name: 'Plus Fitness',
        logo: {
          '@type': 'ImageObject',
          url: logo_blue
        }
      },
      author: {
        '@type': 'Person',
        name: ''
      }
    }

    if (data.author) {
      obj.author = {
        '@type': 'Person',
        name: data.author.name
      }
    }
    return obj
  },
  BreadcrumbList: (data: WagtailPageProps, siteInfor?: SiteProps) => {
    let obj = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: siteInfor?.site_name,
          item: siteInfor?.root_page
        }
      ]
    }
    if (data?.meta?.html_url != siteInfor?.root_page) {
      if (
        data.meta.parent &&
        data.meta.parent?.meta.html_url != siteInfor?.root_page
      ) {
        obj.itemListElement.push({
          '@type': 'ListItem',
          position: 2,
          name: data.meta.parent?.title,
          item: data.meta.parent?.meta.html_url
        })
        obj.itemListElement.push({
          '@type': 'ListItem',
          position: 3,
          name: data.title,
          item: data.meta.html_url || ''
        })
      } else {
        obj.itemListElement.push({
          '@type': 'ListItem',
          position: 2,
          name: data.title,
          item: data.meta.html_url || ''
        })
      }
    }

    return obj
  },
  SearchAction: (siteInfor?: SiteProps) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: siteInfor?.root_page,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${siteInfor?.root_page}gyms/?q={search_term_string}`,
        'query-input': 'required name=search_term_string'
      }
    }
  },
  SEOMetaTags: (siteInfor?: SiteProps, siteSettings?: SiteSettingProps) => {
    const siteAlternates = [
      { country: 'AU', hrefLang: 'en-au', href: 'https://plusfitness.com.au' },
      { country: 'NZ', hrefLang: 'en-nz', href: 'https://plusfitness.co.nz' },
      { country: 'IN', hrefLang: 'en', href: 'https://plusfitness.co.in' },
      { country: 'ASIA', hrefLang: 'en', href: 'https://plusfitness.asia' },
      { country: 'UK', hrefLang: 'en', href: 'https://plusfitness.co.uk' }
    ]

    let obj = {
      title: defaultTitle,
      titleTemplate: `%s`,
      description: defaultDescription,
      openGraph: {
        type: 'website',
        locale: 'en_IE',
        url: siteInfor?.root_page,
        title: defaultTitle,
        description: defaultDescription,
        defaultImageWidth: 1200,
        defaultImageHeight: 1200,
        images: [
          {
            url: `${siteInfor?.root_url}${logo_blue}`,
            width: 283,
            height: 112,
            alt: 'Plus Fitness Logo'
          }
        ],
        site_name: 'Plus Fitness'
      },
      twitter: {
        handle: '@plusfitness',
        site: '@plusfitness',
        cardType: 'summary_large_image'
      },
      languageAlternates: siteAlternates.filter(site => {
        return site.country != siteSettings?.country
      })
    }

    return obj
  }
}
